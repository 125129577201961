<template>
  <video
    :src="info.video"
    :poster="info.bigImg"
    controls="controls"
    style="max-width: 100%"
  ></video>
</template>

<script>
export default {
  props: {
    info: {
      type: Object
    }
  },
  data() {
    return {
      videoState: false // 视频播放状态
    }
  },
  methods: {
    play() {}
  }
}
</script>

<style></style>
