<template>
  <div class="teaching">
    <div class="teaching-title">
      {{ info.title }}
    </div>
    <my-video class="myVideo" :info="info"></my-video>
    <div class="teaching-content">
      <div class="tc-label">教程步骤</div>
      <div class="tc-box">
        <div class="tc-box-title">1、点击左下角“文案库”</div>
        <img :src="getImgUrl('/4/2.png')" class="tc-box-img" alt="图" />
      </div>
      <div class="tc-box">
        <div class="tc-box-title">2、可根据书名后方的爆款指数选择文案</div>
        <img :src="getImgUrl('/5/7.png')" class="tc-box-img" alt="图" />
      </div>
      <div class="tc-box">
        <div class="tc-box-title">3、点击“查看文案”</div>
        <img :src="getImgUrl('/5/1.png')" class="tc-box-img" alt="图" />
      </div>
      <div class="tc-box">
        <div class="tc-box-title">
          4、点击上方“红色标题”，可选择文案版本：原版、清水版。
        </div>
        <img :src="getImgUrl('/5/2.png')" class="tc-box-img" alt="图" />
      </div>
      <div class="tc-box">
        <div class="tc-box-title">5、点击右下角“推广公众号</div>
        <img :src="getImgUrl('/5/3.png')" class="tc-box-img" alt="图" />
      </div>
      <div class="tc-box">
        <div class="tc-box-title">
          6、选择自身所绑定的推广渠道，点击“领取推广任务”即可获得公众号引流信息
        </div>
        <img :src="getImgUrl('/5/4.png')" class="tc-box-img" alt="图" />
      </div>
      <div class="tc-box">
        <div class="tc-box-title">7、去使用文案</div>
        <img :src="getImgUrl('/5/5.png')" class="tc-box-img" alt="图" />
      </div>
      <div class="tc-box">
        <div class="tc-box-title">
          8、右上角即可一键复制文案，自己去做推广作品
        </div>
      </div>
      <div class="tc-box">
        <div class="tc-box-title">
          9、或在平台内进行自动滚屏的录制，左下角点击“开始播放”，右下角即可调整滚屏速度
        </div>
        <img :src="getImgUrl('/5/6.png')" class="tc-box-img" alt="图" />
      </div>
      <div class="teaching-tips">
        <div class="tt-title">注意！！！</div>
        <div class="tt-content">
          文案的完整性十分重要，这关乎着达人们的收益！（所有文案都是经过我们调整过精准定位卡点的，少发、分集发的收益都很差）
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myVideo from './components/video.vue'
import { getCourseDtl } from '@/api/course.js'
export default {
  name: 'course-one',
  components: {
    myVideo
  },
  data() {
    return {
      info: {},
    }
  },
  async mounted() {
    let courseId = this.$route.query.id
    if (courseId) {
      this.info = await getCourseDtl({ id: courseId })
      document.title = this.info.title
    } else {
      this.$router.replace('/course')
    }
  },
  methods: {
    getImgUrl(url) {
      return this.$baseUrl + url
    }
  }
}
</script>

<style scoped lang="less">
.teaching {
  padding: 30px;
  .teaching-title {
    position: relative;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
    padding-left: 20px;
    text-align: left;
  }
  .teaching-content {
    position: relative;
    z-index: 0;
    padding: 30px 0px 0px;
    .tc-label {
      position: relative;
      display: inline-block;
      padding: 8px 32px;
      border-radius: 50px;
      letter-spacing: 2px;
      margin-bottom: 10px;
      font-size: 30px;
      background: #ff8870;
      color: white;
    }
    .tc-box {
      text-align: left;
      margin-top: 40px;
      padding-bottom: 40px;
      border-bottom: 1px solid #eaeaea;
      .tc-box-title {
        color: #ff8870;
        font-size: 30px;
        margin-bottom: 12px;
      }
    }
  }
  .teaching-tips {
    margin-top: 20px;
    text-align: left;
    .tt-title {
      color: red;
      font-size: 40px;
      font-weight: bold;
    }
    .tt-content {
      font-size: 30px;
      line-height: 45px;
      font-weight: bold;
    }
  }
}
.tc-box:last-child {
  border: none !important;
}
.tc-label::before {
  position: absolute;
  bottom: -25%;
  left: -50%;
  content: '';
  width: 390px;
  z-index: -1;
  height: 20px;
  background: #fef3ee;
}
.teaching-title:before {
  position: absolute;
  left: 0px;
  top: 0px;
  content: '';
  width: 10px;
  height: 100%;
  border-radius: 10px;
  background: #fe784c;
}

.myVideo {
  width: 100%;
  height: 400px;
}
</style>
