<template>
  <div>
    <one v-if="id == 1" />
    <two v-if="id == 2" />
    <three v-if="id == 3" />
    <four v-if="id == 4" />
    <five v-if="id == 5" />
    <six v-if="id == 6" />
    <!-- 立即注册按钮 -->
    <div
      v-if="loginFlag"
      @click="
        $router.push({ path: '/register', query: { code: $route.query.code } })
      "
    >
      <img src="./img/lh.png" class="jumpRegister" alt="" />
    </div>
  </div>
</template>

<script>
import one from './one.vue'
import two from './two.vue'
import three from './three.vue'
import four from './four.vue'
import five from './five.vue'
import six from './six.vue'
export default {
  components: {
    one,
    two,
    three,
    four,
    five,
    six
  },
  data() {
    return {
      id: '',
      loginFlag: false
    }
  },
  async mounted() {
    this.loginFlag = window.localStorage.getItem('token') == null
    this.id = this.$route.query.id
    if (!this.id) {
      this.$router.replace('/course')
    }
  },
  methods: {}
}
</script>

<style>
.jumpRegister {
  position: fixed;
  right: 20px;
  /* top: 50%; */
  bottom: 10%;
  width: 221px;
  height: 80px;
}
</style>
