<template>
  <div class="teaching">
    <div class="teaching-title">
      {{ info.title }}
    </div>
    <my-video class="myVideo" :info="info"></my-video>
    <div class="teaching-content">
      <div class="tc-label">教程步骤</div>
      <div class="tc-box">
        <div class="tc-box-title">1.达人的收益从何而来呢</div>
        <div class="tc-box-text">
          推广达人的收益=微信公众号内有效阅读点击量（用户关注公众号后，回复关键数字点击阅读）
        </div>
      </div>
      <div class="tc-box">
        <div class="tc-box-title">2.如何引流？</div>
        <div class="tc-box-text">
          达人发布作品时，分别在“视频中、评论区里”，置放引流信息进行引导，引导用户去关注微信公众号，并回复关键数字。
        </div>
        <div class="tc-box-twoImgs">
          <img :src="getImgUrl('/1/1.png')" class="tc-box-twoImg" />
          <img :src="getImgUrl('/1/2.png')" class="tc-box-twoImg" />
        </div>
        <div class="tc-box-step">
          <div class="tc-box-step-title">
            第一步：在微信的搜索框中输入公众号名称
          </div>
          <img :src="getImgUrl('/1/6.png')" class="tc-box-step-img" alt="" />
        </div>
        <div class="tc-box-step">
          <div class="tc-box-step-title">第二步：进入公众号</div>
          <img :src="getImgUrl('/1/3.png')" class="tc-box-step-img" alt="" />
        </div>
        <div class="tc-box-step">
          <div class="tc-box-step-title">
            第三步：关注公众号后，回复对应关键数字
          </div>
          <img :src="getImgUrl('/1/4.png')" class="tc-box-step-img" alt="" />
          <img
            :src="getImgUrl('/1/5.png')"
            class="tc-box-step-img mt-20"
            alt=""
          />
        </div>
      </div>
    </div>
  
  </div>
</template>

<script>
import myVideo from './components/video.vue'
import { getCourseDtl } from '@/api/course.js'
export default {
  name: 'course-one',
  components: {
    myVideo
  },
  data() {
    return {
      info: {},
    }
  },
  async mounted() {
    let courseId = this.$route.query.id
    if (courseId) {
      this.info = await getCourseDtl({ id: courseId })
      document.title = this.info.title
    } else {
      this.$router.replace('/course')
    }
  },
  methods: {
    getImgUrl(url) {
      return this.$baseUrl + url
    }
  }
}
</script>

<style scoped lang="less">
.teaching {
  padding: 30px;
  .teaching-title {
    position: relative;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
    padding-left: 20px;
    text-align: left;
  }
  .teaching-content {
    position: relative;
    z-index: 0;
    padding: 30px 0px 0px;
    .tc-label {
      position: relative;
      display: inline-block;
      padding: 8px 32px;
      border-radius: 50px;
      letter-spacing: 2px;
      margin-bottom: 10px;
      font-size: 30px;
      background: #ff8870;
      color: white;
    }
    .tc-box {
      text-align: left;
      margin-top: 40px;
      border-bottom: 1px solid #eaeaea;
      .tc-box-title {
        color: #ff8870;
        font-size: 30px;
      }
      .tc-box-text {
        font-size: 26px;
        line-height: 36px;
        padding: 12px 0px 40px;
      }
      .tc-box-twoImgs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 60px;
        .tc-box-twoImg {
          width: 50%;
          // width: 325px;
          // height: 284px;
          background: #5d5d5d;
        }
      }
      .tc-box-step {
        margin-bottom: 40px;
        .tc-box-step-title {
          display: inline-block;
          font-weight: bold;
          font-size: 28px;
          margin-bottom: 30px;
          color: #666666;
          border-bottom: 4px solid #666666;
        }
        .tc-box-step-img {
          object-fit: contain;
        }
      }
    }
  }
}
.tc-box:last-child {
  border: none !important;
}
.tc-label::before {
  position: absolute;
  bottom: -25%;
  left: -50%;
  content: '';
  width: 390px;
  z-index: -1;
  height: 20px;
  background: #fef3ee;
}
.teaching-title:before {
  position: absolute;
  left: 0px;
  top: 0px;
  content: '';
  width: 10px;
  height: 100%;
  border-radius: 10px;
  background: #fe784c;
}
.mt-20 {
  margin-top: 20px;
}
.myVideo {
  width: 100%;
  height: 400px;
}
</style>
