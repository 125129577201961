<template>
  <div class="teaching">
    <div class="teaching-title">
      {{ info.title }}
    </div>
    <my-video class="myVideo" :info="info"></my-video>
    <div class="teaching-content">
      <div class="tc-label">教程步骤</div>
      <div class="tc-box">
        <div class="tc-box-title">1、点击左下角“文案库”</div>
        <img :src="getImgUrl('/4/2.png')" class="tc-box-img" alt="图" />
      </div>
      <div class="tc-box">
        <div class="tc-box-title">
          2、直接点击最上方输入框，输入关键词完成搜索。
        </div>
        <img :src="getImgUrl('/4/3.png')" class="tc-box-img" alt="图" />
      </div>
      <div class="tc-box">
        <div class="tc-box-title">
          3、搜索下方，可根据推广产品、风络、人群、类型进行“筛选”。同时，也可根据爆款指数、上线时间、文案更新时间进行“排序”。
        </div>
        <img :src="getImgUrl('/4/1.png')" class="tc-box-img" alt="图" />
      </div>
    </div>
  </div>
</template>

<script>
import myVideo from './components/video.vue'
import { getCourseDtl } from '@/api/course.js'
export default {
  name: 'course-one',
  components: {
    myVideo
  },
  data() {
    return {
      info: {},
    }
  },
  async mounted() {
    let courseId = this.$route.query.id
    if (courseId) {
      this.info = await getCourseDtl({ id: courseId })
      document.title = this.info.title
    } else {
      this.$router.replace('/course')
    }
  },
  methods: {
    getImgUrl(url) {
      return this.$baseUrl + url
    }
  }
}
</script>

<style scoped lang="less">
.teaching {
  padding: 30px;
  .teaching-title {
    position: relative;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
    padding-left: 20px;
    text-align: left;
  }
  .teaching-content {
    position: relative;
    z-index: 0;
    padding: 30px 0px 0px;
    .tc-label {
      position: relative;
      display: inline-block;
      padding: 8px 32px;
      border-radius: 50px;
      letter-spacing: 2px;
      margin-bottom: 10px;
      font-size: 30px;
      background: #ff8870;
      color: white;
    }
    .tc-box {
      text-align: left;
      margin-top: 40px;
      padding-bottom: 40px;
      border-bottom: 1px solid #eaeaea;
      .tc-box-title {
        color: #ff8870;
        font-size: 30px;
        margin-bottom: 12px;
      }
    }
  }
}
.tc-box:last-child {
  border: none !important;
}
.tc-label::before {
  position: absolute;
  bottom: -25%;
  left: -50%;
  content: '';
  width: 390px;
  z-index: -1;
  height: 20px;
  background: #fef3ee;
}
.teaching-title:before {
  position: absolute;
  left: 0px;
  top: 0px;
  content: '';
  width: 10px;
  height: 100%;
  border-radius: 10px;
  background: #fe784c;
}

.myVideo {
  width: 100%;
  height: 400px;
}
</style>
