<template>
  <div class="teaching">
    <div class="teaching-title">
      {{ info.title }}
    </div>
    <my-video class="myVideo" :info="info"></my-video>
    <div class="teaching-content">
      <div class="tc-label">教程步骤</div>
      <div class="tc-box">
        <div class="tc-box-title">
          1、打开微信，搜索公众号：海文达人助手。完成关注，即可正常使用。
        </div>
        <img :src="getImgUrl('/2/1.png')" class="tc-box-img" alt="图" />
      </div>
      <div class="tc-box">
        <div class="tc-box-title">2、点击右下角“个人中心”</div>
        <img :src="getImgUrl('/2/2.png')" class="tc-box-img" alt="图" />
      </div>
      <div class="tc-box">
        <div class="tc-box-title">
          3、点击界面最下方“
          <span @click="$router.push('/register')" style="color: skyblue"
            >还没有账号？立即注册</span
          >
          ”
        </div>
        <img :src="getImgUrl('/2/3.png')" class="tc-box-img" alt="图" />
      </div>
      <div class="tc-box">
        <div class="tc-box-title">
          4、输入个人姓名、微信ID、手机号，接收短信验证码后填写“顾问邀请码”
        </div>
        <img :src="getImgUrl('/2/4.png')" class="tc-box-img" alt="图" />
      </div>
    </div>
  </div>
</template>

<script>
import myVideo from './components/video.vue'
import { getCourseDtl } from '@/api/course.js'
export default {
  name: 'course-one',
  components: {
    myVideo
  },
  data() {
    return {
      info: {},
    }
  },
  async mounted() {
    let courseId = this.$route.query.id
    if (courseId) {
      this.info = await getCourseDtl({ id: courseId })
      document.title = this.info.title
    } else {
      this.$router.replace('/course')
    }
  },
  methods: {
    getImgUrl(url) {
      return this.$baseUrl + url
    }
  }
}
</script>

<style scoped lang="less">
.teaching {
  padding: 30px;
  .teaching-title {
    position: relative;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
    padding-left: 20px;
    text-align: left;
  }
  .teaching-content {
    position: relative;
    z-index: 0;
    padding: 30px 0px 0px;
    .tc-label {
      position: relative;
      display: inline-block;
      padding: 8px 32px;
      border-radius: 50px;
      letter-spacing: 2px;
      margin-bottom: 10px;
      font-size: 30px;
      background: #ff8870;
      color: white;
    }
    .tc-box {
      text-align: left;
      margin-top: 40px;
      padding-bottom: 40px;
      border-bottom: 1px solid #eaeaea;
      .tc-box-title {
        color: #ff8870;
        font-size: 30px;
        margin-bottom: 12px;
      }
      .tc-box-img {
      }
    }
  }
}
.tc-box:last-child {
  border: none !important;
}
.tc-label::before {
  position: absolute;
  bottom: -25%;
  left: -50%;
  content: '';
  width: 390px;
  z-index: -1;
  height: 20px;
  background: #fef3ee;
}
.teaching-title:before {
  position: absolute;
  left: 0px;
  top: 0px;
  content: '';
  width: 10px;
  height: 100%;
  border-radius: 10px;
  background: #fe784c;
}

.myVideo {
  width: 100%;
  height: 400px;
}
</style>
