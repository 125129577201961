<template>
  <div class="teaching">
    <div class="teaching-title">
      {{ info.title }}
    </div>
    <my-video class="myVideo" :info="info"></my-video>
    <div class="teaching-content">
      <div class="tc-label">教程步骤</div>
      <div class="tc-box">
        <div class="tc-box-title">1、打开推广的视频作品，选择“复制链接”。</div>
        <img :src="getImgUrl('/6/1.png')" class="tc-box-img" alt="图" />
      </div>
      <div class="tc-box">
        <div class="tc-box-title">2、返回平台，点击我的推广</div>
        <img :src="getImgUrl('/6/2.png')" class="tc-box-img" alt="图" />
      </div>
      <div class="tc-box">
        <div class="tc-box-title">3、点击回传链接输入框。</div>
        <img :src="getImgUrl('/6/3.png')" class="tc-box-img" alt="图" />
      </div>
      <div class="tc-box">
        <div class="tc-box-title">
          4、粘贴刚刚复制的推广视频链接，然后点击“保存”
        </div>
        <img :src="getImgUrl('/6/4.png')" class="tc-box-img" alt="图" />
        <img :src="getImgUrl('/6/5.png')" class="tc-box-img" alt="图" />
      </div>
      <div class="teaching-tips">
        <div class="tt-title">注意！！！</div>
        <div class="tt-content">
          提交推广作品的链接成功后，数据才能开始统计！
          没有回传与任务对应的作品链接，就没有收益！（回传错误可修改）
        </div>
      </div>
      <div class="tc-box">
        <div class="tc-box-title">5、引流信息</div>
        <div class="tc-box-text">
          将已经复制好的引流信息，包括公众号名字、回复关键数字，粘贴到推广视频作品的评论区里，完成置顶。
        </div>
      </div>
      <div class="tc-box">
        <div class="tc-box-title">
          6、评论区置放引流信息的方式比较多，下方为一种置放引流信息的方式
        </div>

        <div class="tc-box-text">
          首先评论： 回复“
          <span style="color: #ff8870">10001122</span> ”，并设置置顶<br />
          置顶成功后，在该条评论里回复楼中楼： <br />
          <span style="color: #ff8870">丿厶丶@纵@呺 </span><br />
          <span style="color: #ff8870">@南鸢书社 </span>
        </div>
        <div class="tc-box-text">如下图，仅是多种方式其中的个别案例：</div>
        <div class="tc-box-twoImgs">
          <img :src="getImgUrl('/6/6.png')" class="tc-box-twoImg" />
          <img :src="getImgUrl('/6/7.png')" class="tc-box-twoImg" />
          <img :src="getImgUrl('/6/8.png')" class="tc-box-twoImg" />
        </div>
        <div class="teaching-tips xhx">
          <div class="tt-title">注意！！！</div>
          <div class="tt-content">
            每次达人发布作品后在评论区置放完引流信息一定要用陌生设备查验一下自己的引流信息是否被吞！
          </div>
        </div>
        <div class="teaching-tips">
          <div class="tt-title">注意！！！</div>
          <div class="tt-content">
            <div>引流信息的完整性十分重要，这关乎着达人们的收益！</div>
            <div>第一、表明引流方向为公众号【可用通假字或谐音字来代替】</div>
            <div>
              第二、公众号名称要准确无误【不能有错别字，不然会影响引流】
            </div>
            <div>
              第三、文案所对应的关键数字不能放错【用户回错关键数字就代表无收益】
            </div>
            <div style="white-space: nowrap">
              以上关于引流信息完整性的三部分，<span style="color: red">
                缺一不可！！！
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myVideo from './components/video.vue'
import { getCourseDtl } from '@/api/course.js'
export default {
  name: 'course-one',
  components: {
    myVideo
  },
  data() {
    return {
      info: {}
    }
  },
  async mounted() {
    let courseId = this.$route.query.id
    if (courseId) {
      this.info = await getCourseDtl({ id: courseId })
      document.title = this.info.title
    } else {
      this.$router.replace('/course')
    }
  },
  methods: {
    getImgUrl(url) {
      return this.$baseUrl + url
    }
  }
}
</script>

<style scoped lang="less">
.teaching {
  padding: 30px;
  .teaching-title {
    position: relative;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
    padding-left: 20px;
    text-align: left;
  }
  .teaching-content {
    position: relative;
    z-index: 0;
    padding: 30px 0px 0px;
    .tc-label {
      position: relative;
      display: inline-block;
      padding: 8px 32px;
      border-radius: 50px;
      letter-spacing: 2px;
      margin-bottom: 10px;
      font-size: 30px;
      background: #ff8870;
      color: white;
    }
    .tc-box {
      text-align: left;
      margin-top: 40px;
      padding-bottom: 40px;
      border-bottom: 1px solid #eaeaea;
      .tc-box-title {
        color: #ff8870;
        font-size: 30px;
        margin-bottom: 12px;
      }
      .tc-box-twoImgs {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        padding-bottom: 60px;
        .tc-box-twoImg {
          width: 50%;
          // width: 325px;
          // height: 284px;
          background: #5d5d5d;
        }
      }
      .tc-box-text {
        margin-bottom: 10px;
      }
    }
  }
  .teaching-tips {
    margin-top: 20px;
    text-align: left;
    .tt-title {
      color: red;
      font-size: 40px;
      font-weight: bold;
    }
    .tt-content {
      font-size: 30px;
      line-height: 45px;
      font-weight: bold;
      div {
        margin-top: 10px;
      }
    }
  }
  .xhx {
    border-bottom: 2px solid #999;
    padding-bottom: 20px;
  }
}
.tc-box:last-child {
  border: none !important;
}
.tc-label::before {
  position: absolute;
  bottom: -25%;
  left: -50%;
  content: '';
  width: 390px;
  z-index: -1;
  height: 20px;
  background: #fef3ee;
}
.teaching-title:before {
  position: absolute;
  left: 0px;
  top: 0px;
  content: '';
  width: 10px;
  height: 100%;
  border-radius: 10px;
  background: #fe784c;
}

.myVideo {
  width: 100%;
  height: 400px;
}
</style>
